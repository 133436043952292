.p-sidebar-mask {
  top: 3.5rem !important;
}
.p-sidebar-right .p-sidebar {
  width: 80% !important;
}
.p-sidebar {
  border: none !important;
}
.p-sidebar-header {
  display: none !important;
}

/* -------Accordion------- */
.mobile-navbar-drawer > .p-accordion-header-link {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem 0rem !important;
}
.mobile-navbar-drawer > .p-accordion-header-link > .p-accordion-header-text {
  color: #333;
  font-family: Lexend;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150% !important; /* 1.875rem */
}

.mobile-navbar-drawer > .p-accordion > .p-accordion-content {
  padding: 0rem 0rem 1rem 1.25rem !important;
}
.mobile-navbar-drawer > .p-accordion-content {
  border: none !important;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
