.game-changer-card-wrapper {
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.game-changer-card-wrapper:hover {
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.16);
}
