.api-automation-hero-section-background {
  background-image: url("../../../assets/apiAutomationMobileBackgroundImage.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 1024px) {
  .api-automation-hero-section-background {
    background-image: url("../../../assets/apiAutomationDesktopBackgroundImage.png");
  }
}
